import {collection} from "firebase/firestore";
import {firebaseFirestore} from "../../utils/firebase";

enum Collections {
    CATEGORIES = "categories",
    PRODUCTS = "products"
}

const CollectionCategories = collection(firebaseFirestore, Collections.CATEGORIES);
const CollectionProducts = collection(firebaseFirestore, Collections.PRODUCTS);

export {CollectionCategories, CollectionProducts};
