import logoImg from "../../img/logo.png";
import React from "react";
import "./styles.css";

const NotFoundView = () => {
    return (
        <div className='not-found-container'>
            <img className='not-found-brand' src={logoImg} alt='Shazam Store'/>
            <p className='not-found-text'>Página no encontrada</p>
        </div>
    )
}

export default NotFoundView;