import MainPageView from "../main-page/MainPageView";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Product from "../../firebase/firestore/services/products/types";
import Category from "../../firebase/firestore/services/categories/types";
import {
    fetchAllProductsPagination,
    fetchProductsByCategoryPagination
} from "../../firebase/firestore/services/products/productService";
import Carousel from "../../components/carousel/Carousel";
import carouselAdsImg1 from "../../img/carousel-ads-1.png";
import carouselAdsImg2 from "../../img/carousel-ads-2.png";
import carouselAdsImg3 from "../../img/carousel-ads-3.png";
import carouselAdsImg4 from "../../img/carousel-ads-4.png";
import CarouselCategories from "../../components/carousel-categories/CarouselCategories";
import CardGrid from "../../components/card-grid/CardGrid";

const HomeView = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const numPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

    const [products, setProducts] = useState<Product[]>([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true);
    const [categorySelected, setCategorySelected] = React.useState<Category | undefined>(undefined);
    const [hasNextPage, setHasNextPage] = React.useState<boolean>(false);

    // const [numPage, setNumPage] = React.useState<number>(1);
    // const [operationPagination, setOperationPagination] = React.useState<string | undefined>(undefined); // ['next', 'before']
    const maxElements = 12;

    const handleButtonBefore = () => {
        // setOperationPagination('before');
        // setNumPage(numPage - 1);
    }

    const handleButtonNext = () => {
        // setOperationPagination('next')
        // setNumPage(numPage + 1);
    }

    useEffect(() => {
            setIsLoadingProducts(true); // Establecer isLoadingProducts en true cuando comienza la carga

            setHasNextPage(false);

            const lastId = products.length > 0 ? products[products.length - 1].id : undefined;

            const firstId = numPage > 1 && products.length > 0 ? products[0].id : undefined;

            fetchAllProductsPagination(firstId, lastId, maxElements)
                .then(r => {
                    if (r.length > maxElements) {
                        setProducts(r.slice(0, maxElements));
                        setHasNextPage(true);
                    } else {
                        setProducts(r);
                    }
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                    setProducts([]);
                })
                .finally(() => {
                    setIsLoadingProducts(false);
                });
        },
        [numPage]
    );

    useEffect(() => {
        setIsLoadingProducts(true);

        // setNumPage(1);

        setHasNextPage(false);

        const firstId = numPage > 1 ? products[0].id : undefined;
        const lastId = products.length > 0 && numPage > 1 ? products[products.length - 1].id : undefined;

        if (categorySelected) {
            fetchProductsByCategoryPagination(categorySelected.id, '', 10).then(
                r => {
                    if (r.length > maxElements) {
                        setProducts(r.slice(0, maxElements));
                        setHasNextPage(true);
                    } else {
                        setProducts(r);
                    }
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                    setProducts([]);
                })
                .finally(() => {
                    setIsLoadingProducts(false);
                });
        } else {
            fetchAllProductsPagination(firstId, lastId, maxElements)
                .then(r => {
                    if (r.length > maxElements) {
                        setProducts(r.slice(0, maxElements));
                        setHasNextPage(true);
                    } else {
                        setProducts(r);
                    }
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                    setProducts([]);
                })
                .finally(() => {
                    setIsLoadingProducts(false);
                });
        }
    }, [categorySelected]);

    const content = (
        <>
            <section className="section">
                <Carousel images={[carouselAdsImg1, carouselAdsImg2, carouselAdsImg3, carouselAdsImg4]}/>
            </section>
            <section className="section">
                <div className="container">
                    <div className='divider-title'></div>
                    <h2 className='section-title'>NUESTRAS CATEGORÍAS</h2>
                </div>
                <CarouselCategories setProducts={setProducts} setCategorySelected={setCategorySelected}/>
            </section>
            <section className="section">
                <div className="container">
                    <div className='divider-title'></div>
                    <h2 className='section-title'>{categorySelected ? categorySelected.title : 'NUESTROS PRODUCTOS'}</h2>
                </div>
                <CardGrid products={products} setProducts={setProducts} isLoadingProducts={isLoadingProducts}
                          setIsLoadingProducts={setIsLoadingProducts} maxElements={maxElements} numPage={numPage}
                          categorySelected={categorySelected}/>
                <div className="container-pagination">
                    <div className="container-text-pagination">
                        <p className="text-pagination">Página {numPage}</p>
                    </div>
                    <div className="container-buttons-pagination">
                        {
                            numPage > 1 &&
                            <Link to={numPage - 1 < 2 ? "/" : "?page=" + (numPage - 1)}
                                  className="btn-pagination-prev"
                            >Anterior
                            </Link>
                        }
                        {
                            hasNextPage &&
                            <Link to={numPage + 1 > 1 ? "?page=" + (numPage + 1) : "/"}
                                  className="btn-pagination-next"
                            >Siguiente</Link>
                        }
                    </div>
                </div>
            </section>
        </>
    );

    return (
        <MainPageView children={content}/>
    );
}

export default HomeView;