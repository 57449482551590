import React, {useEffect, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import './style.css';
import {fetchAllCategories} from "../../firebase/firestore/services/categories/categoryService";
import Category from "../../firebase/firestore/services/categories/types";
import Product from "../../firebase/firestore/services/products/types";
import {
    fetchAllProductsPagination,
    fetchProductsByCategoryPagination
} from "../../firebase/firestore/services/products/productService";

interface CarouselProps {
    setCategorySelected: React.Dispatch<React.SetStateAction<Category | undefined>>;
    setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
}

const responsive = {
    0: {
        items: 2,
    },
    600: {
        items: 3,
    },
    1000: {
        items: 5,
    }
}

const CarouselCategories: React.FC<CarouselProps> = ({setCategorySelected, setProducts}) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(true);
    const [, setCategoriesData] = useState<Category[]>([]);
    const [activeId, setActiveId] = useState<string | null>(null);
    const [forceRender, setForceRender] = useState<string>("");

    useEffect(() => {
        fetchAllCategories().then(r => setCategories(r));
    }, []);

    useEffect(() => {
        setCategoriesData(categories);
        setIsLoadingCategories(false);
    }, [categories]);

    const handleClick = (item: Category) => {
        const isActive = activeId === item.id;
        setActiveId(
            isActive ? null : item.id
        );
        setCategorySelected(isActive ? undefined : item);
        setForceRender(isActive ? "" : item.id.toString());
    };

    if (isLoadingCategories) {
        return (
            <OwlCarousel className='owl-theme' key={forceRender} dots={false} nav={false} responsive={responsive}>
                {[...Array(5)].map((_, index) => (
                    <div key={index} className="item category-item category-item-loading"/>
                ))}
            </OwlCarousel>
        );
    }

    return (
        <>
            <OwlCarousel className='owl-theme' key={forceRender} lazyLoad dots={true} nav={false}
                         responsive={responsive}>
                {categories.map((item, index) => (
                    <div key={index}
                         className={`item category-item${activeId && activeId === item.id ? ' active' : ''}`}
                         onClick={() => handleClick(item)}>
                        <img className="category-img" src={item.imageUrl} alt={item.title}/>
                        <p className="category-title">{item.title}</p>
                    </div>
                ))}
            </OwlCarousel>
        </>
    );
};

export default CarouselCategories;
