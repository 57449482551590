import {Link} from "react-router-dom";
import logoImg from "../../img/logo.png";
import searchIcon from "../../icon/icon-search-solid.svg";
import userIcon from "../../icon/icon-user-solid.svg";
import bagIcon from "../../icon/icon-bag-shopping-solid.svg";
import React from "react";

const Navbar = () => {
    return (
        <>
            <nav className="navbar">
                <div className="navbar__left">
                    <Link to="/">
                        <img src={logoImg} alt="Shazam Store" className="navbar__logo"/>
                    </Link>
                    <div className="navbar__search">
                        <input type="text" placeholder="Buscar..."/>
                        <button className="search-button" type="button">
                            <img src={searchIcon} alt="Buscar" className="svg-icon search"/>
                        </button>
                    </div>
                </div>
                <div className="navbar__right">
                    <Link to="/perfil">
                        <div className="navbar__link">
                            <img src={userIcon} alt="Perfil" className="svg-icon"/>
                            <p>Perfil</p>
                        </div>
                    </Link>
                    <Link to="/cesta">
                        <div className="navbar__link">
                            <img src={bagIcon} alt="Cesta" className="svg-icon"/>
                            <p>Cesta</p>
                            <span className="badge">9+</span>
                        </div>
                    </Link>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
