import {FaDiscord, FaFacebook, FaInstagram, FaTiktok} from "react-icons/fa";
import React from "react";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="contact-info">
                    <h3 className='contact-item title'>TELÉFONO</h3>
                    <p className='contact-item'>&nbsp;(+51) 908 759 926</p>
                    <h3 className='contact-item title'>CORREO ELECTRÓNICO</h3>
                    <p className='contact-item'>&nbsp;ventas@shazamstore.com</p>
                    <h3 className='contact-item title'>HORARIO DE ATENCIÓN</h3>
                    <p className='contact-item'>&nbsp;lun-sáb: 8:00 hrs. - 18:00 hrs.</p>
                </div>
                <div className="social-media">
                    <h3 className='contact-item title'>REDES SOCIALES</h3>
                    <ul className="social-icons">
                        <li className='social-icons-item'>
                            <a href="https://www.facebook.com/ShazamStorePeru/" target='_blank'
                               rel="noreferrer"><FaFacebook className='social-icon'/></a>
                        </li>
                        <li className='social-icons-item'>
                            <a href="https://www.instagram.com/shazam_store_pe/" target='_blank'
                               rel="noreferrer"><FaInstagram className='social-icon'/></a>
                        </li>
                        <li className='social-icons-item'>
                            <a href="https://www.tiktok.com/@shazamstorepe/" target='_blank'
                               rel="noreferrer"><FaTiktok className='social-icon'/></a>
                        </li>
                        <li className='social-icons-item'>
                            <a href="https://discord.gg/R8AZTYgSYQ/" target='_blank'
                               rel="noreferrer"><FaDiscord
                                className='social-icon'/></a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="divider"/>
            <p className="copyright">Shazam Store © 2024. Todos los derechos reservados.</p>
        </footer>
    );
}

export default Footer;