import {getDocs, query, where, orderBy} from "firebase/firestore";
import Category from "./types";
import {CollectionCategories} from "../../collections";

const fetchAllCategories = async (): Promise<Category[]> => {
    try {
        const queryCategories = query(
            CollectionCategories,
            where("statusId", "==", 'lavljxRp4nclLyC6lfJp'),
            orderBy("title")
        );

        const querySnapshot = await getDocs(queryCategories);

        const categories: Category[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();

            const category: Category = {
                id: doc.id || '',
                title: data.title || '',
                imageUrl: data.imageUrl || ''
            };

            categories.push(category);
        });

        return categories;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};

export {
    fetchAllCategories
}