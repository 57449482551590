import React, {useEffect, useState} from 'react';
import './style.css';
import {Link} from "react-router-dom";
import noProductsImage from '../../img/products-not-found.svg';
import Category from "../../firebase/firestore/services/categories/types";
import Product from "../../firebase/firestore/services/products/types";
import {fetchAllProductsPagination} from "../../firebase/firestore/services/products/productService";

export interface CardProps {
    product: Product;
}

const Card: React.FC<CardProps> = ({product}) => {
    return (
        <div className="card">
            <div className="card-container-img">
                <img src={product.imageUrls[0]} className="card-img" alt={product.title}/>
            </div>
            <Link className="card-title" to={`/producto/${product.id}`}>{product.title}</Link>
            <div className="card-footer">
                <p className="card-prize">S/{product.price}</p>
                <button className="card-button" onClick={() => {
                    console.log(`Producto ${product.id} añadido a la cesta`)
                }}>AÑADIR A LA CESTA
                </button>
            </div>
        </div>
    );
};

interface CardGridProps {
    products: Product[];
    setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
    isLoadingProducts: boolean;
    setIsLoadingProducts: React.Dispatch<React.SetStateAction<boolean>>;
    categorySelected: Category | undefined;
    numPage: number;
    maxElements: number;
}

const CardGrid: React.FC<CardGridProps> = ({
                                               products,
                                               setProducts,
                                               isLoadingProducts,
                                               setIsLoadingProducts,
                                               maxElements,
                                               numPage
                                           }) => {

    if (isLoadingProducts) {
        return <Loader/>;
    }

    if (products.length === 0) {
        return <NoProductsMessage/>;
    }

    return (
        <div className="card-grid">
            {products.map((product, index) => (
                <Card key={index} product={product}/>
            ))}
        </div>
    );
};

function Loader() {
    return (
        <div className="products-loading-container">
            <div className="lds-dual-ring"></div>
        </div>
    );
}

function NoProductsMessage() {
    return (
        <div className="no-products-container">
            <img src={noProductsImage} alt="No hay productos disponibles" className="no-products-image"/>
            <p className="no-products-text">¡Lo sentimos! No hay productos disponibles en este momento.</p>
        </div>
    );
}

export default CardGrid;
