import React, {ChangeEvent, useEffect, useState} from "react";
import Category from "../../../firebase/firestore/services/categories/types";
import {fetchAllCategories} from "../../../firebase/firestore/services/categories/categoryService";
import {addDoc, collection, doc, Timestamp, updateDoc} from "firebase/firestore";
import {firebaseFirestore, firebaseStorage} from "../../../utils/firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import "./styles.css"

interface FormProductProps {
    handleLogout: () => void;
}

const FormProductView: React.FC<FormProductProps> = ({handleLogout}) => {
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {

        fetchAllCategories()
            .then(r => {
                setCategories(r);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, []);

    const [title, setTitle] = React.useState<string>('');
    const [price, setPrice] = React.useState<number>(0.0);
    const [categoryIds, setCategoryIds] = React.useState<string[]>([]);
    const [image, setImage] = useState<File | null>(null);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPrice(Number(event.target.value));
    };

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setCategoryIds((prev) => {
            if (prev.includes(value)) {
                return prev.filter((prevValue) => prevValue !== value);
            }
            return [...prev, value];
        });
    };

    const handleSave = async () => {
        setIsLoadingSave(true);

        interface ProductSave {
            title: string;
            price: number;
            categoryIds: string[];
            imageUrls: string[];
            registrationDate: Timestamp;
            statusId: string;
        }

        let imageUrls: string[] = [];
        const registrationDate = Timestamp.now();
        const statusId = 'lavljxRp4nclLyC6lfJp';

        const product: ProductSave = {
            title,
            price,
            categoryIds,
            imageUrls,
            registrationDate,
            statusId
        };

        const collectionRef = collection(firebaseFirestore, "products");

        await addDoc(collectionRef, product).then(async r => {
                if (image) {
                    const storageRef = ref(firebaseStorage, `products/${r.id}/${image.name}`);

                    await uploadBytes(storageRef, image).then(async (snapshot) => {
                        await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                            await updateDoc(
                                doc(collectionRef, r.id),
                                {
                                    imageUrls: [downloadURL]
                                }
                            )
                                .then(() => {
                                    console.log(`Document ${r.id} successfully written!`);
                                })
                                .catch((error) => {
                                    console.error("Error writing document: ", error);
                                });
                        })
                            .catch((error) => {
                                console.error("Error getting download URL: ", error);
                            });
                    }).catch((error) => {
                        console.error("Error uploading image: ", error);
                    });
                }
            }
        ).finally(() => {
            setIsLoadingSave(false);
        });
    }

    return (
        <>
            <div className="container-form-products">
                <input
                    type="text"
                    placeholder="Titulo"
                    value={title}
                    onChange={handleTitleChange}
                    className="input-text"
                    disabled={isLoadingSave}
                />
                <input
                    type="number"
                    placeholder="Precio"
                    value={price}
                    min={0}
                    onChange={handlePriceChange}
                    className="input-number"
                    disabled={isLoadingSave}
                />
                <div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="input-file"
                        disabled={isLoadingSave}
                    />
                    {image && (
                        <div className="image-preview">
                            <img src={URL.createObjectURL(image)} alt="Imagen" width="150px" height="150px"/>
                        </div>
                    )}
                </div>
                <div>
                    {categories.map((category) => (
                        <label key={category.id} className="checkbox-label">
                            <input
                                type="checkbox"
                                value={category.id}
                                checked={categoryIds.includes(category.id)}
                                onChange={handleFieldChange}
                                disabled={isLoadingSave}
                            />
                            &nbsp;{category.title}
                        </label>
                    ))}
                </div>
                <button onClick={handleSave} className="save-button"
                        disabled={isLoadingSave || (
                            !title || price <= 0 || categoryIds.length === 0 || !image
                        )}>
                    Guardar
                </button>
                {isLoadingSave && <p>Guardando...</p>}
            </div>
            <button onClick={handleLogout} className="logout-button"
                    disabled={isLoadingSave}>
                Cerrar Sesión
            </button>
        </>
    )
}

export default FormProductView;