import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {firebaseAuth} from "./utils/firebase";
import {signOut, onAuthStateChanged} from "firebase/auth";
import {User} from "@firebase/auth";
import LoadView from "./views/load/LoadView";
import NotFoundView from "./views/not-found/NotFoundView";
import LoginView from "./views/login/LoginView";
import ProductView from "./views/product/ProductView";
import HomeView from "./views/home/HomeView";
import FormProductView from "./views/private/form-product/FormProduct";

function App() {
    const [_, setCurrentUser] = useState<User | null>(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [isLoginLoading, setIsLoginLoading] = useState(true);

    const handleLogout = async () => {
        try {
            await signOut(firebaseAuth);
            setCurrentUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    useEffect(() => {
        return onAuthStateChanged(firebaseAuth, initializeUser);
    }, []);

    async function initializeUser(user: any) {
        if (user) {
            setCurrentUser({...user});
            setUserLoggedIn(true);
        } else {
            setCurrentUser(null);
            setUserLoggedIn(false);
        }
        setIsLoginLoading(false);
    }

    if (isLoginLoading) {
        return <LoadView/>
    }

    return (
        <Routes>
            <Route path="/" element={<HomeView/>}/>
            <Route
                path="/products"
                element={!isLoginLoading && userLoggedIn ? (
                    <section className="section">
                        <FormProductView handleLogout={handleLogout}/>
                    </section>
                ) : (
                    <Navigate to="/login" replace/>
                )}
            />
            <Route
                path="/login"
                element={
                    userLoggedIn ? <Navigate to="/products" replace/> :
                        <LoginView isLoginLoading={isLoginLoading}
                                   setIsLoginLoading={setIsLoginLoading}
                                   setCurrentUser={setCurrentUser}
                                   setUserLoggedIn={setUserLoggedIn}
                        />
                }
            />
            <Route path="/producto/:id" element={<ProductView/>}/>
            <Route path="*" element={<NotFoundView/>}/>
        </Routes>
    );
}

export default App;
