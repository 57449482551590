import React from 'react';
import OwlCarousel from "react-owl-carousel";

interface CarouselProps {
    images: string[];
}

const responsive = {
    0: {
        items: 1,
    }
}

const Carousel: React.FC<CarouselProps> = ({images}) => {
    return (
        <OwlCarousel className='owl-theme' responsive={responsive} autoplay={true} lazyLoad loop={true} dots={true}
                     autoplayHoverPause={true} autoplayTimeout={10000}>
            {images.map((image, index) => (
                <div
                    key={index}
                    className="item"
                >
                    <img src={image} alt={`Slide ${index}`}/>
                </div>
            ))}
        </OwlCarousel>
    );
};

export default Carousel;
