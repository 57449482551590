import MainPageView from "../main-page/MainPageView";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Product from "../../firebase/firestore/services/products/types";
import {fetchProductById} from "../../firebase/firestore/services/products/productService";
import "./styles.css"
import logoImg from "../../img/logo-white.svg";

const ProductView = () => {
    const productId = useParams().id || "";
    const [product, setProduct] = useState<Product | undefined>(undefined);

    const features = [
        'Transductor: 40 mm',
        'Respuesta de frecuencia: 20 Hz - 20 kHz',
        'Impedancia: 36 ohmios',
        'Sensibilidad: 87,5 dB SPL/mW',
        'Toma de audio de 3,5 mm: Plug and Play',
        'Certificación Discord: Comunicaciones nítidas',
        'Patrón de captación: Cardioide (unidireccional)',
        'Respuesta de frecuencia: (100 Hz-10 kHz)',
        'Radio de acción inalámbrico: Hasta 12 m',
        'Duración de batería (recargable): 33 h 1'
    ]

    useEffect(() => {
        fetchProductById(productId)
            .then((product) => {
                setProduct(product);
            });
    }, []);

    const content = (
        <>
            <section className="section">
                <div className="container-product-info">
                    <div className="product-images">
                        <img src={product?.imageUrls[0]} alt={product?.title} width="100%" height="auto"/>
                    </div>
                    <div className="product-data">
                        <h1 className="product-title">{product?.title}</h1>
                        <p className="product-description">Disfruta del ultrarrápido desempeño inalámbrico de LIGHTSPEED
                            con los audífonos Logitech G535. Juega libre de cables y con todo confort durante sesiones
                            de juego prolongadas. Completamente equipados, con transductores de 40 mm, certificación
                            Discord y mucho más.</p>
                        <p className="product-price">S/{product?.price}</p>
                        <button className="btn btn-add-to-cart">Añadir a la cesta</button>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container-product-features">
                    <div className="container">
                        <div className='divider-title'></div>
                        <h2 className='section-title'>CARACTERÍSTICAS</h2>
                    </div>
                    <ul>
                        {features.map((feature, index) => (
                            <li key={index} className="product-feature">• {feature}</li>
                        ))}
                    </ul>
                </div>
            </section>
            <section className="section-banner">
                <div className="container-banner-shazam">
                    <img src={logoImg} width="250px" alt="Banner Shazam Store"/>
                </div>
            </section>
        </>
    );

    return (
        <MainPageView children={content}/>
    );
}

export default ProductView;