import React, {ChangeEvent, useState} from "react";
import {signInWithEmailAndPassword} from "firebase/auth";
import {firebaseAuth} from "../../utils/firebase";
import {User} from "@firebase/auth";
import "./styles.css";

interface LoginProps {
    setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
    setUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoginLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoginLoading: boolean;
}

const LoginView: React.FC<LoginProps> = ({setCurrentUser, setUserLoggedIn, isLoginLoading, setIsLoginLoading}) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleLogin = async () => {
        setIsLoginLoading(true);
        try {
            const user = await signInWithEmailAndPassword(
                firebaseAuth,
                email,
                password
            );
            setCurrentUser(user.user);
            setUserLoggedIn(true);
        } catch (error) {
            console.error('Login error:', error);
        } finally {
            setIsLoginLoading(false);
        }
    };

    return (
        <>
            <div className="container-login-form">
                <input
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    className="input-email"
                    placeholder="Correo electrónico"
                    disabled={isLoginLoading}
                />
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="input-password"
                    placeholder="Contraseña"
                    disabled={isLoginLoading}
                />
                <button onClick={handleLogin} className="login-button" disabled={isLoginLoading}>
                    Iniciar Sesión
                </button>
            </div>
        </>
    );
};

export default LoginView;