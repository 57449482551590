import {doc, endAt, getDoc, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import Product from "./types";
import {CollectionProducts} from "../../collections";

const fetchAllProductsPagination = async (firstId: string | undefined, lastId: string | undefined, maxElements: number): Promise<Product[]> => {
    try {
        let firstVisible = firstId ? await getDoc(doc(CollectionProducts, firstId)) : undefined;

        const lastVisible = lastId ? await getDoc(doc(CollectionProducts, lastId)) : undefined;


        let queryCategories = undefined;

        if (!firstVisible && !lastVisible) {
            queryCategories = query(
                CollectionProducts,
                where("statusId", "==", 'lavljxRp4nclLyC6lfJp'),
                limit(maxElements + 1)
            );
        }

        if (firstVisible) {
            queryCategories = query(
                CollectionProducts,
                where("statusId", "==", 'lavljxRp4nclLyC6lfJp'),
                orderBy("__name__", "asc"),
                endAt(firstVisible),
                limit(maxElements + 1)
            );
        }

        if (lastVisible) {
            queryCategories = query(
                CollectionProducts,
                where("statusId", "==", 'lavljxRp4nclLyC6lfJp'),
                startAfter(lastVisible),
                limit(maxElements + 1)
            );
        }

        // @ts-ignore
        const querySnapshot = await getDocs(queryCategories);

        const products: Product[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();

            const product: Product = {
                id: doc.id || '',
                title: data.title || '',
                description: data.description || '',
                price: data.price || 0,
                imageUrls: data.imageUrls || [],
                categoryIds: data.categoryIds || []
            };

            products.push(product);
        });

        return products;
    } catch (error) {
        console.error('Error fetching products:', error);
        return [];
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchProductsByCategoryPagination = async (categoryId: string, lastId: string, maxElements: number): Promise<Product[]> => {
    try {
        const queryCategories = query(
            CollectionProducts,
            where("categoryIds", "array-contains", categoryId),
            where("statusId", "==", 'lavljxRp4nclLyC6lfJp'),
            limit(maxElements)
        );

        const querySnapshot = await getDocs(queryCategories);

        const products: Product[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();

            const product: Product = {
                id: doc.id,
                title: data.title,
                description: data.description || '',
                price: data.price,
                imageUrls: data.imageUrls,
                categoryIds: data.categoryIds
            };

            products.push(product);
        });

        return products;
    } catch (error) {
        console.error('Error fetching products by category:', error);
        return [];
    }
};

const fetchProductById = async (productId: string): Promise<Product | undefined> => {
    try {
        const docRef = doc(CollectionProducts, productId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();

            return {
                id: docSnap.id,
                description: data.description || '',
                title: data.title,
                price: data.price,
                imageUrls: data.imageUrls,
                categoryIds: data.categoryIds
            };
        } else {
            return undefined;
        }
    } catch (error) {
        console.error('Error fetching product by id:', error);
        return undefined;
    }
}

export {
    fetchAllProductsPagination,
    fetchProductsByCategoryPagination,
    fetchProductById
}
