import {initializeApp} from "firebase/app";
import {initializeAppCheck, ReCaptchaEnterpriseProvider} from "firebase/app-check";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {getFirestore, Firestore} from 'firebase/firestore';
import {getStorage} from "firebase/storage";

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY || '';
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '';
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '';
const appId = process.env.REACT_APP_FIREBASE_APP_ID || '';
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '';
const recaptchaEnterpriseSiteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY || '';

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase App Check
const firebaseAppCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(recaptchaEnterpriseSiteKey),
    isTokenAutoRefreshEnabled: true
});

// Initialize Firebase Authentication
const firebaseAuth = getAuth(firebaseApp);

// Initialize Firebase Firestore
const firebaseFirestore = getFirestore(firebaseApp) as Firestore;

// Initialize Firebase Analytics
const firebaseAnalytics = getAnalytics(firebaseApp);

// Initialize Firebase Storage
const firebaseStorage = getStorage(firebaseApp);

export {
    firebaseApp,
    firebaseAppCheck,
    firebaseAuth,
    firebaseFirestore,
    firebaseAnalytics,
    firebaseStorage
};
