import lightningImg from "../../img/rayo.png";
import React from "react";
import "./styles.css";

const LoadView = () => {
    return (
        <>
            <div className="loader-container">
                <img src={lightningImg} className="loader-lightning" alt="Rayo"/>
            </div>
        </>
    );
}

export default LoadView;