import React, {ReactNode} from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

interface MainPageViewProps {
    children?: ReactNode;
}

const MainPageView: React.FC<MainPageViewProps> = ({children}) => {
    return (
        <>
            <Navbar/>
            <div className="space-navbar"></div>
            {children}
            <Footer/>
        </>
    );
}

export default MainPageView;